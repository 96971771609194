/******************************************************************
BUTTONS & LINKS
******************************************************************/
.btn.btn-primary {
  background-color: #004a39;
  border-color: #004a39;
  color: white;
  margin-bottom: 10px;
}

.btn.btn-primary:hover,
.btn.btn-primary:focus .btn.btn-primary:active, .btn.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #004a39;
  border-color: #004a39;
  color: #00b088;
  box-shadow: none;
}

.btn.btn-outline-primary {
  border-color: #004a39;
  color: #004a39;
  margin-bottom: 10px;
}

.btn.btn-outline-primary:hover, .btn.btn-outline-primary:focus, .btn.btn-outline-primary:active, .btn.btn-outline-primary:not(:disabled):not(.disabled):active {
  background: #004a39;
  border-color: #004a39;
  color: white;
  box-shadow: none;
}

.btn.btn-outline-primary.btn-sm {
  font-weight: bold;
}

a {
  color: #004a39;
}

a:hover, a:focus, a:active {
  color: #00b088;
  text-decoration: none;
}

/******************************************************************
HEADINGS
******************************************************************/
h1, h2, h3, h4, h5, h6 {
  color: #004a39;
}

/******************************************************************
TYPOGRAPHY
******************************************************************/
p {
  color: #333;
  font-size: 1.1rem;
}

/******************************************************************
HEADER
******************************************************************/
.ch-header {
  position: relative;
  z-index: 10;
  box-shadow: 0 0.3125rem 0.875rem 0 rgba(129, 129, 129, 0.2) !important;
}

.ch-logo {
  height: 60px;
}

@media (min-width: 576px) {
  .ch-logo {
    height: 65px;
  }
}

@media (min-width: 768px) {
  .ch-logo {
    height: 70px;
  }
}

@media (min-width: 992px) {
  .ch-logo {
    height: 90px;
  }
}

.ch-navbar {
  padding-top: 0;
  padding-bottom: 0;
}

.ch-main-nav {
  padding: 10px 0;
}

.ch-main-nav li.ch-nav-item a.ch-nav-link {
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
  color: #004a39;
}

.ch-main-nav li.ch-nav-item.active a.ch-nav-link,
.ch-main-nav li.ch-nav-item a:hover.ch-nav-link {
  background-color: #004a39;
  color: white;
}

@media (min-width: 768px) {
  .ch-main-nav {
    padding: 0;
  }
  .ch-main-nav li.ch-nav-item a.ch-nav-link {
    padding: 35px 20px;
    border-radius: 0;
  }
}

@media (min-width: 992px) {
  .ch-main-nav li.ch-nav-item a.ch-nav-link {
    padding: 45px 30px;
  }
}

/******************************************************************
MAIN
******************************************************************/
/******************************************************************
HOME SECONDARY CONTENT
******************************************************************/
.ch-dark-bg {
  background: #004a39;
  color: white;
}

.ch-dark-bg h1, .ch-dark-bg h2, .ch-dark-bg h3, .ch-dark-bg h4, .ch-dark-bg h5, .ch-dark-bg h6, .ch-dark-bg a, .ch-dark-bg p {
  color: white;
}

/******************************************************************
SERVICES
******************************************************************/
.ch-service-icon {
  width: 150px;
}

.ch-service-icon__lg {
  width: 200px;
}

/******************************************************************
TEAM
******************************************************************/
.ch-team-photo {
  max-width: 300px;
}

@media (min-width: 768px) {
  .ch-team-photo {
    max-width: 100%;
  }
}

.ch-team-overview .ch-team-photo {
  width: 100%;
  max-width: 250px;
}

/******************************************************************
LEGAL POINT NOTATION
******************************************************************/
.legal-point-notation .underline {
  text-decoration: underline;
}

.legal-point-notation ol {
  counter-reset: section;
}

.legal-point-notation ol li {
  counter-increment: section;
  content: counter(section) ". ";
  margin: 1rem 0 1rem 0;
  text-indent: 0.5rem;
}

.legal-point-notation > ol > li > ol {
  margin-left: 3rem;
}

.legal-point-notation ol li ol {
  counter-reset: clause;
  list-style: none outside none;
  text-indent: -1rem;
}

.legal-point-notation ol li ol li {
  list-style-type: none;
  counter-increment: clause;
  padding-left: 1rem;
}

.legal-point-notation ol li ol li:before {
  content: counter(section) "." counters(clause, ".") ". ";
  margin: 0 0.5rem 0 0.5rem;
}

.legal-point-notation ol > li > ol > li {
  text-indent: -3rem;
}

.legal-point-notation ol > li > ol > li > ol > li {
  text-indent: -3.25rem;
}

.legal-point-notation ol > li > ol > li > ol > li > ol > li {
  text-indent: -4rem;
}

.legal-point-notation ol li ol li dl {
  padding-left: 3.5rem;
}

.legal-point-notation ol li p {
  text-indent: 0;
}

.legal-point-notation ol > li > p {
  margin-left: 0.5rem;
}

.legal-point-notation ol.alpha li {
  list-style-type: lower-alpha;
  text-indent: -1rem;
}

.legal-point-notation ol.roman li {
  list-style-type: lower-roman;
  text-indent: -1rem;
}

.legal-point-notation ol.alpha li:before,
.legal-point-notation ol.roman li:before {
  content: ' ';
}

.legal-point-notation table {
  margin: 1rem 0;
  border: 1px solid #ccc;
  border-width: 1px 0px 0px 1px;
}

.legal-point-notation table thead tr td {
  font-weight: bold;
  padding: 8px 10px;
}

.legal-point-notation table tr td {
  padding: 0px 10px;
  border: 1px solid #ccc;
  border-width: 0px 1px 1px 0px;
  vertical-align: text-top;
  text-indent: 0;
}

/******************************************************************
FOOTER
******************************************************************/
.ch-footer {
  background-color: #004a39;
  color: white;
  box-shadow: 0 400px 0 #004a39;
}

.ch-footer a, .ch-footer p {
  color: white;
  text-decoration: none;
}

.ch-footer .ch-footer-nav a {
  font-weight: bold;
}

.ch-footer .ch-footer-nav a:hover {
  color: #00b088;
}

.ch-footer .ch-footer-nav__secondary a {
  margin: 0 12px;
  display: inline-block;
}
